import { _AuthApi } from "api/auth";
import { _axios as Axios } from "../interceptor/http-config";
const showSnackbar = (enqueueSnackbar, message, variant) => {
  enqueueSnackbar(message, {
    variant,
    autoHideDuration: 4000,
    anchorOrigin: { vertical: "bottom", horizontal: "right" },
  });
};
export const HttpResponseInterceptor = (navigate, enqueueSnackbar) => {
  Axios.interceptors.response.use(
    function (response) {
      switch (response?.config?.method) {
        case "post":
          showSnackbar(enqueueSnackbar, "Done", "success");
          break;
        case "put":
          showSnackbar(enqueueSnackbar, "Updated", "success");
          break;
        case "patch":
          showSnackbar(enqueueSnackbar, response.data.message, "success");
          break;
        case "delete":
          showSnackbar(enqueueSnackbar, "Deleted", "success");
          break;
        default:
          break;
      }
      return response;
    },
    function (error) {
      switch (error?.response?.status) {
        case 404:
          showSnackbar(enqueueSnackbar, error.response.data.message, "error");
          alert("error");
          break;
        case 422:
          Object.keys(error.response.data.message).map((key) => {
            showSnackbar(
              enqueueSnackbar,
              error.response.data.message[key],
              "error"
            );
          });
          break;
        case 500:
          showSnackbar(enqueueSnackbar, error.response.data.message, "error");
          break;
        case 405:
          showSnackbar(enqueueSnackbar, error.response.data.message, "error");
          break;
        case 401:
          _AuthApi.destroyToken();
          navigate("/");
          break;
        case 403:
          _AuthApi.destroyToken();
          navigate("/");
          break;
        default:
          showSnackbar(enqueueSnackbar, error.response?.data?.message, "error");
          break;
      }
      return Promise.reject(error);
    }
  );
};

// hello
