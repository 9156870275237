import React from "react";
import { Route, Routes } from "react-router-dom";

import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import ShouldNotBeLogged from "./middlewares/ShouldNotBeLogged";
import ShouldBeLogged from "./middlewares/ShouldBeLogged";
import Loader from "./components/shared/Loader";
import Login from "./pages/Login";

const DashboardRouting = React.lazy(() =>
  import("./modules/dashboard/DashboardRouting")
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // refetchOnWindowFocus: false,
      // retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 24000000000,
    },
  },
});

const AppRouting = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ShouldNotBeLogged>
            <Login />
          </ShouldNotBeLogged>
        }
      />

      <Route
        path="Dashboard/*"
        element={
          <ShouldBeLogged>
            <React.Suspense fallback={<Loader />}>
              <QueryClientProvider client={queryClient}>
                <DashboardRouting />
                <ReactQueryDevtools initialIsOpen={false} />
              </QueryClientProvider>
            </React.Suspense>
          </ShouldBeLogged>
        }
      />
    </Routes>
  );
};

export default AppRouting;
